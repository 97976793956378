import * as React from "react";
import { TipChip } from "app/subframe/components/TipChip";
import { AsInput } from "app/subframe/components/AsInput";
import { AsButton } from "app/subframe/components/AsButton";
import { PaymentStepType } from "app/payment/usePayment";

const tipOptions = [18, 20, 22];

export interface TipsModalProps {
  setPaymentStep: (step: PaymentStepType) => void;

  onTipOptionSelect: (tipPercent: number) => void;
  onCustomTipOptionChange: (e: any) => void;
  selectedTipOption: number | null;
  customTip: number | null;
}

export const TipsModal: React.FC<TipsModalProps> = ({
  setPaymentStep,
  onTipOptionSelect,
  onCustomTipOptionChange,
  selectedTipOption,
  customTip,
}) => {
  return (
    <div className="flex w-full flex-none flex-col items-center gap-6 rounded-lg  mobile:w-full">
      <div className={"group/fe4ad82d flex w-full items-center gap-4"}>
        <span
          className={
            "grow shrink-0 basis-0 text-[18px] font-[500] leading-[28px] text-neutral-900"
          }
        >
          Tip options
        </span>
        <AsButton
          variant="ghost"
          text="Back"
          size="xs"
          onClick={() => {
            setPaymentStep(PaymentStepType.INITIAL);
          }}
        />
      </div>
      <div className="flex w-full items-center gap-2">
        {tipOptions.map((tip: any) => (
          <TipChip
            className="h-10 grow shrink-0 basis-0"
            text={`${tip}%`}
            active={selectedTipOption === tip}
            onClick={() => onTipOptionSelect(tip)}
          />
        ))}
      </div>
      <div className="flex w-full flex-col items-start justify-center gap-4">
        <span className="w-full text-body font-body text-neutral-900">
          Custom amount
        </span>
        <AsInput
          leading="$"
          placeholder="0"
          type="number"
          containerClassName="w-full"
          onChange={onCustomTipOptionChange}
          value={customTip?.toString()}
        />
      </div>
      <AsButton
        className="h-14 w-full flex-none"
        variant="default"
        text="Pay now"
        size="lg"
        onClick={() => setPaymentStep(PaymentStepType.PAY_WITH)}
      />
    </div>
  );
};
